import React from 'react';
import styled from 'styled-components'
import { Link } from "gatsby"
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu"

const Wrapper = styled.div`
  width: 100%;
  background-color: #0f0f0f;
  padding: 15px 135px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: 992px) {
    padding: 20px;
  }
`;

const Logo = styled.img`
  height: 45px;

  @media (max-width: 992px) {
    height: 35px;
  }
`;

const Navigation = styled.ul`
  display: flex;
  padding-inline-start: 0;
  
  @media (max-width: 1200px) {
    display: none;
  }
`

const NavigationItem = styled.li`
  list-style: none;
  text-decoration: none;
  color: white;
  font-family: "Playfair Display", sans-serif;
  margin-right: 40px;
  
  &:hover {
    border-bottom: 1px solid rgb(255,255,255,0.6);
  }
  
  
 
 &:last-child {
    margin-right: 0;
 }
`

const StyledLink = styled(Link)`
  width: 300px;
`

const Header = () => {
  return (
    <Wrapper>
      <StyledLink to="/">
        <Logo src="/lastchance.svg"/>
      </StyledLink>

        <HamburgerMenu />
        <Navigation>
          <NavigationItem activeStyle={{ borderBottom: '1px solid white'}} as={Link} to="/klienci-indywidualni">
            Klienci indywidualni
          </NavigationItem>
          <NavigationItem activeStyle={{ borderBottom: '1px solid white'}} as={Link} to="/podmioty-gospodarcze">
            Podmioty gospodarcze
          </NavigationItem>
          <NavigationItem activeStyle={{ borderBottom: '1px solid white'}} as={Link} to="/kontakt">
            Kontakt
          </NavigationItem>
        </Navigation>
    </Wrapper>
  )
}

export default Header;