import React from "react"
import styled from "styled-components"
import {Link} from "gatsby"

const MenuToggle = styled.div`
  position: relative;
  top: 0;
  right: 0;
  
  z-index: 1;
  
  -webkit-user-select: none;
  user-select: none;
  
  display: none;
  
  @media (max-width: 1200px) {
    display: block;
  }
`

const HiddenInput = styled.input`
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  right: -7px; 
  cursor: pointer; 
  opacity: 0; 
  z-index: 2; 
  
  -webkit-touch-callout: none;
  
    &:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -1px);
    }
    
    &:checked ~ span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }
    
    &:checked ~ span:nth-last-child(2) {
      transform: rotate(-45deg) translate(0, -1px);
    }
    
    &:checked ~ ul {
       transform: none;
    }
    
    &:checked ~ body {
      overflow: hidden;
    }
`

const Span = styled.span`
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  
  background: #fff;
  border-radius: 3px;
  
  z-index: 1;
  
  transform-origin: 4px 0;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
              
    &:first-child {
      transform-origin: 0 0;
    }
    
    &:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
`

const Menu = styled.ul`
  margin: -100px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
  background: #2D3137;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  
  transform-origin: 0% 0%;
  transform: translate(100%, 0);
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
`

const Li = styled.li`
  padding: 20px 0;
  border-bottom: 1px solid white;
  color: #fff;
  font-size: 20px;
  text-align: center;
  font-family: "Playfair Display", serif;
  
  &:last-of-type {
    border-bottom: none;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #fff;
`

const HamburgerMenu = () => (
  <nav role="navigation">
    <MenuToggle>
      <HiddenInput type="checkbox"/>
      <Span />
      <Span />
      <Span />
      <Menu>
        <div>
          <Li><StyledLink to="/klienci-indywidualni">Klienci indywidualni</StyledLink></Li>
          <Li><StyledLink to="/podmioty-gospodarcze">Podmioty gospodarcze</StyledLink></Li>
          <Li><StyledLink to="/kontakt">Kontakt</StyledLink></Li>
        </div>
      </Menu>
    </MenuToggle>
  </nav>
)

export default HamburgerMenu;