import React from 'react';
import styled, { css } from "styled-components"
import { Link } from "gatsby"

const Wrapper = styled.div`
  background-color: #2d3236;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px 0 45px 0;
  flex-direction: column;
  position: absolute;
  width: 100%;
`

const InnerWrapper = styled.div`
  display: flex;
  
  @media (max-width: 992px) {
    flex-direction: column;
  }
`

const Menu = styled.ul`
  padding: 20px 70px 20px 80px;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 992px) {
    margin: 0 0 20px 0;
    padding: 15px 0 15px 0;
  }
`

const MenuItem = styled.li`
  list-style: none;
  text-decoration: none;
  color: #fff;
  font-size: 17px;
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  text-align: center;
  margin-bottom: 8px;
  
  &:last-child {
      margin-bottom: 0;   
  }
 
  
  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 13px;
  }
  
  @media (min-width: 992px) {
    padding-left: 80px;
  }
  
  @media (max-width: 992px) {
    font-size: 14px;
  }
`

const Hr = styled.hr`
  width: 100px;
  color: #fff;
  display: none;
  border-bottom: none;
  
  @media (max-width: 992px) {
    display: inline;
  }
`

const Info = styled.div`
  padding: 0 80px;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  font-family: "Work Sans", sans-serif;
  font-size: 17px;
  font-weight: 300;
  line-height: 36px;
  
  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 13px;
    line-height: 30px;
  }
  
  ${({border}) => border 
    && css`
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
    `};
  
  @media (max-width: 992px) {
    margin-bottom: 20px;
    font-size: 14px;
    border: none;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
`

const Logo = styled.img`
  height: 50px;
  margin-top: 90px;
  
  @media (max-width: 1200px) {
    height: 45px;
  }
`

const Footer = () => (
  <Wrapper>
    <InnerWrapper>
      <Menu>
        <MenuItem as={Link} to="/klienci-indywidualni">
          Klienci indywidualni
        </MenuItem>
        <MenuItem as={Link} to="/podmioty-gospodarcze">
          Podmioty gospodarcze
        </MenuItem>
        <MenuItem as={Link} to="/polityka-prywatnosci">
          Polityka prywatności
        </MenuItem>
        <MenuItem as={Link} to="/kontakt">
          Kontakt
        </MenuItem>
      </Menu>
      <Hr />
      <Info border>
        kancelaria@radcabm.pl <br />
        tel. 508 343 810
      </Info>
      <Hr />
      <Info>
        Rachunek bankowy: <br />
        51 1950 0001 2006 0603 7464 0002
        <br />
        NIP 574-189-52-40
      </Info>
      <Hr />
    </InnerWrapper>
    <Logo src="/logo.svg" />
  </Wrapper>
);

export default Footer;